import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ClassnamePartial,
  CodeSnippet,
  EnvironmentPartial,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  QaIdPartial,
  Section,
} from '../../../components';
import V5Notice from '../v5-notice/V5Notice';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const SelectMark = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.SelectMark;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../data/snippets/select-mark.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <V5Notice
        code
        componentName="Select Mark"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-select-mark--docs"
      />
      <CodeSnippet
        scope={{ React: React, SelectMark: SelectMark }}
        code={snippet}
        platform="react"
        gitHubLink="select-mark"
      />

      <Section title="Props">
        <PropList header="Visual">
          <ClassnamePartial componentName="select mark" />

          <EnvironmentPartial />

          <PropListItem name="isHovered" types={['boolean']}>
            <Text>
              Determines whether to force the hover state of the select mark.
              Useful when you want to show the select mark's hover state when
              hovering over a large parent element like a video thumbnail.
            </Text>
          </PropListItem>

          <PropListItem name="selectedState" types={['string']}>
            <Text>
              Determines whether the select mark is selected. This should be
              controlled by state through the <code>onClick</code> prop. If a
              user is leaving a multi-select mode, set this to{' '}
              <code>default</code>. If a user is deselecting a single object,
              set this to <code>unselected</code>.
            </Text>
            <List type="unordered">
              <li>
                <code>default</code> (default)
              </li>
              <li>
                <code>selected</code>
              </li>
              <li>
                <code>unselected</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="size" types={['string']}>
            <Text>Determines the size of the select mark.</Text>
            <List type="unordered">
              <li>
                <code>small</code>
              </li>
              <li>
                <code>medium</code> (default)
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>
        </PropList>

        <PropList header="Events">
          <PropListItem name="onClick" types={['(e?) => void']} isRequired>
            <Text>Responds to the select mark click event.</Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <QaIdPartial componentName="select mark" />
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>
            Since the Select Mark is stateless, the selected state of the Select
            Mark needs to be handled with <code>onClick</code>.
          </li>
          <li>
            For accessibility purposes, <code>onClick</code> will be called when
            the spacebar is hit. Additionally, the
            <code> role</code> is set as <code>checkbox</code> with a supporting{' '}
            <code>aria-checked</code> attribute to help with users using screen
            readers.
          </li>
        </List>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React"
        componentName="Select Mark"
      />
    </PlatformTab>
  );
};

export default WebTab;
