import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  CodeSnippet,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
  AccessibilityLabelPartial,
  TestIdPartial,
  SpacePartial,
} from '../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../data/snippets/rn-select-mark.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        code={snippet}
        platform="react-native"
        gitHubLink="select-mark"
        disableCodeEditing
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="selectedState" types={['string']}>
            <Text>
              Determines whether the select mark is selected. This should be
              controlled by state through the <code>onPress</code> prop. If a
              user is leaving a multi-select mode, set this to{' '}
              <code>default</code>. If a user is deselecting a single object,
              set this to <code>unselected</code>.
            </Text>
            <List type="unordered">
              <li>
                <code>default</code> (default)
              </li>
              <li>
                <code>selected</code>
              </li>
              <li>
                <code>unselected</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="size" types={['string']}>
            <Text>Determines the size of the select mark.</Text>
            <List type="unordered">
              <li>
                <code>small</code>
              </li>
              <li>
                <code>medium</code> (default)
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>

          <SpacePartial />
        </PropList>

        <PropList header="Events">
          <PropListItem name="onPress" types={['() => void']} isRequired>
            <Text>Responds to the select mark press event.</Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <AccessibilityLabelPartial componentName="select mark" />

          <TestIdPartial componentName="select mark" />
        </PropList>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React Native"
        componentName="Select Mark"
      />
    </PlatformTab>
  );
};

export default ReactNativeTab;
